<template>
  <v-dialog
    v-model="dialog"
    persistent
    style="z-index:200000001;"
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        PRIVACY POLICY / TERMS AND CONDITIONS<br />
        APPLICATION "HEALTHY COMMUNITIES"
      </v-card-title>
      <v-card-text>
        I. GENERAL CONDITIONS <br />
        These general conditions (hereinafter CONDITIONS), regulate the terms
        and conditions of access and use of the computer application called
        CARNÉ DE VACUNACIÓN (hereinafter CARNÉ DE VACUNACIÓN), which THE USER
        must read, understand and accept to make use of this and get the
        following services: Allow the User to have access to the registered
        information about their applied vaccines. List of georeferenced health
        establishments where vaccines are applied at the national level.
        Reference information on the vaccines considered in the current national
        vaccination schedule. For the purposes of these CONDITIONS, VACCINATION
        CARD shall be understood as the external appearance of the screen
        interfaces, both statically and dynamically, or the elements integrated
        both in the screen interfaces and in the navigation tree, including, by
        way of example and not limitation, those texts, images, sounds,
        databases, multimedia products, interpretations, artistic executions,
        fixations, photographs, broadcast signals, and in general, all those
        creations and objects increased by any means or support, currently known
        or believed to be in the future, whether or not they are protected by
        the current legal system in terms of intellectual or industrial property
        or by any other analogous legal systems and all those services or online
        resources that, if applicable, are offered by the users. THE MINSA
        reserves the right to modify, at any time and without prior notice, the
        presentation and configuration of the VACCINATION CARD, as well as the
        contents and services included in it. THE USER acknowledges and accepts
        that at any time THE MINSA may interrupt, deactivate and/or cancel any
        of the contents and services that are integrated in the VACCINATION
        CARD.
      </v-card-text>
      <v-card-actions class="justify-center" @click="onClose">
        <v-btn color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
};
</script>
