<template>
  <v-img
    src="@/assets/bg_landing.png"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
    >
      <v-card class="mr-3 mt-10 justify-center" max-width="500px" color="white">
        <v-card-title class="mx-3 pb-0">
          <div
            style="font-size: 25px; font-family: 'Poppins-SemiBold'; margin-top: 10px;"
          >
            Register
          </div>
        </v-card-title>
        <v-card-title class="mx-3 mb-0 pb-0">
          <v-text-field
            v-model="first_name"
            label="First Name"
            dense
            rounded
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            hide-details
            style="width:100%"
          />
          <v-text-field
            v-model="phone"
            label="Phone number"
            dense
            rounded
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            hide-details
            type="tel"
            style="width:100%"
          />
          <v-text-field
            v-model="zip"
            label="Zip"
            dense
            rounded
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            hide-details
            style="width:100%"
          />
          <v-text-field
            v-model="email"
            label="Email Address"
            dense
            rounded
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            hide-details
            type="email"
            style="width:100%"
          />
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Password"
            dense
            rounded
            filled
            outlined
            background-color="white"
            class="mt-3 mb-3"
            full-width
            style="width: 100%"
            hide-details
          />
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>
        </v-card-title>
        <v-card-title class="mt-0 pt-0">
          <v-checkbox v-model="agreePolicy" class="ml-3" @change="onChange">
            <template v-slot:label>
              <div class="d-flex">
                I accept
                <div class="url-button ml-1" @click.prevent.stop="openPrivacy">
                  Terms and Privacy of Use
                </div>
              </div>
            </template>
          </v-checkbox>
          <v-btn
            rounded
            dark
            color="#7024c4"
            width="100%"
            @click="registerClicked"
            :loading="loading"
          >
            Register
          </v-btn>
        </v-card-title>
        <v-card-title class="justify-center mt-0 pt-0">
          <v-btn text class="text-none mb-4" @click="goToLogin" rounded>
            Login
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
    <priacy-dialog :dialog="privacyDialog" :onClose="onCloseDialog" />
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
import PriacyDialog from "./PriacyDialog.vue";

export default {
  components: { PriacyDialog },
  data() {
    return {
      email: "",
      password: "",
      phone: "",
      zip: "",
      first_name: "",
      loading: false,
      errorMessage: null,
      agreePolicy: false,
      privacyDialog: false,
      rules: {
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      onRegister: "auth/onRegister",
    }),
    registerClicked() {
      if (this.loading) return;
      if (!this.agreePolicy) {
        this.errorMessage = "You should agree the privacy and policy";
        return;
      }
      const params = {
        email: this.email,
        password: this.password,
        first_name: this.first_name,
        zip: this.zip,
        phone: this.phone,
      };
      this.loading = true;
      console.log(params);
      this.onRegister(params)
        .then(() => {
          this.loading = false;
          this.emailError = null;
          this.passwordError = null;
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    openPrivacy() {
      this.privacyDialog = true;
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    onCloseDialog() {
      this.privacyDialog = false;
    },
  },
};
</script>
<style>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 50px;
  text-shadow: 2px 2px 12px #0006;
  color: white;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  padding-left: 20px;
  display: block;
}
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
